<template>
  <div>
    <PageLoader />
    <!-- breadcrumb area start -->
    <div class="breadcrumb-area mb-30">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-wrap">
              <nav aria-label="breadcrumb">
                <ul class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Networks
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- breadcrumb area end -->
    <!-- brand sale area start -->
    <div class="brand-area pb-70">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h3>
                <span> Our Networks</span>
              </h3>
            </div>
          </div>
          <div class="col-12">
            <swiper class="swiper" :options="swiperOption">
              <template v-for="(networks, i) in networks">
                <swiper-slide :key="networks.title + i">
                  <div class="product-item">
                    <div class="product-thumb">
                      <router-link
                        :to="
                          '/networks/' +
                            networks.slug +
                            '?network=' +
                            networks.slug
                        "
                        tag="a"
                      >
                        <img class="d-block m-auto" :src="publicPath + networks.img" alt/>
                      </router-link>
                    </div>
                    <div class="product-caption">
                      <div class="product-name">
                        <h4>
                          <a href="product-details.html"
                            >Offers {{ networks.offers.length }}</a
                          >
                        </h4>
                        <h4>
                          <a href="product-details.html">{{
                            networks.title
                          }}</a>
                        </h4>
                      </div>
                      <!-- <div class="ratings">
                    <span class="yellow">
                      <i class="lnr lnr-star"></i>
                    </span>
                    <span class="yellow">
                      <i class="lnr lnr-star"></i>
                    </span>
                    <span class="yellow">
                      <i class="lnr lnr-star"></i>
                    </span>
                    <span>
                      <i class="lnr lnr-star"></i>
                    </span>
                    <span>
                      <i class="lnr lnr-star"></i>
                    </span>
                      </div>-->
                      <router-link
                        class="btn-cart"
                        :to="
                          '/networks/' +
                            networks.slug +
                            '?network=' +
                            networks.slug
                        "
                        tag="a"
                        >View</router-link
                      >
                    </div>
                  </div>
                  <!-- </div> end single item -->
                </swiper-slide>
              </template>
              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!-- brand sale area end -->
  </div>
</template>

<script>
export default {
  name: "networkCopm",
  data() {
    return {
       publicPath: "https://app.contractsexpert.co.uk/storage/app/public/",
      networks: []
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    getFullPath() {
      return this.$route.path;
    }
  },
  watch: {
    getFullPath() {
      this.getData();
    }
  },
  methods: {
    getData() {
      this.loading = true;
      this.$urlmain
        .get("network-popular.json")
        .then(response => {
          this.networks = response.data.data;
          response => (this.networks = response.data.data);
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
